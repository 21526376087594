.slider-wrapper {
  position: relative;
  height: 70vh;
  /* overflow: hidden; */
}

.slide {
  height: 70vh;
  background-size: cover !important;
}

.slide::before {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(transparent),
    to(rgba(0, 0, 0, 0.9))
  );
  background: rgba(0, 0, 0, 0.4);
  bottom: 0;
  left: 0;
}

.previousButton,
.nextButton {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  background: url(../images/icon/angle_right.png) no-repeat center center / 32px;
  width: 60px;
  height: 60px;
  text-indent: -9999px;
  cursor: pointer;
  background-color: rgba(255, 255, 255, 0.15);
}

.previousButton:hover,
.nextButton:hover {
  background: url(../images/icon/angle_right.png) no-repeat center center / 32px;
  background-color: rgba(255, 255, 255, 0.25);
}

.previousButton {
  left: 0;
  -webkit-transform: rotate(180deg) translateY(calc(50% + 0px));
  transform: rotate(180deg) translateY(calc(50% + 0px));
}

.previousButton:hover {
  left: -10px;
}

.nextButton {
  right: 0;
}

.nextButton:hover {
  right: -10px;
}

.slider-content {
  text-align: center;
}

.slider-content .inner {
  padding: 0 70px 70px 70px;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}

.slider-content .sliderStyle2 button {
  -webkit-transition-delay: 0s;
  transition-delay: 0s;
}

.slider-content .inner h1 {
  font-weight: 900;
  margin: 0 auto;
  max-width: 840px;
  color: #ffffff;
  font-size: 64px;
  line-height: 1;
}

/* .slider-content section {
    position: absolute;
    bottom: 20px;
    left: 20px;
  } */

.slider-content section span {
  color: #ffffff;
}

.slider-content section span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  display: inline-block;
  text-align: left;
  line-height: 1.4;
  vertical-align: middle;
  margin-left: 10px;
}

.slider-content section img {
  width: 40px;
  height: 40px;
  border: solid 2px rgba(255, 255, 255, 0.5);
  border-radius: 100%;
  vertical-align: middle;
}

.slider-content section span strong {
  color: #ffffff;
  font-size: 14px;
  display: block;
}

@media (max-height: 500px) {
  .slider-wrapper,
  .slide {
    height: calc(100vh - 75px);
  }
}

@media (max-width: 640px) {
  .slider-wrapper,
  .slide {
    height: calc(80vh - 75px);
  }
  .parent-slider-wrapper {
    position: relative;
    overflow: hidden;
  }

  .parent-slider-wrapper:after {
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    background: transparent;
    top: 0;
    left: 0;
    touch-action: pan-y;
    overflow: scroll;
  }
}

@media (max-height: 600px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}

@media (max-width: 640px) {
  .slider-content .inner h1 {
    font-size: 32px;
  }
}
.slider-wrapper.home-slider2 {
  height: 80vh;
}
.slider-wrapper.home-slider2 .track {
  height: 100%;
}
.slider-wrapper.home-slider2 .track .slide {
  height: 100%;
}


.slider-hero-video {
  height: 100vh;
}

@media (max-width: 1290px) {
  .slider-hero-video {
    height: auto;
  }}

  /* @media (min-width: 768px) {
    .slider-hero-video {
      height: auto;
    }
} */

@media (max-width: 991px) {
  .main-bar {
    background-color: #f7941d;
  }}

  /* @media (min-width:576px) {

  
    .col-sm-6 {
      -ms-flex : 0 0 50%;
      flex     : 0 0 100%;
      max-width: 100%
    }
 
  }
  
  @media (min-width:768px) {

  
    .col-md-6 {
      -ms-flex : 0 0 50%;
      flex     : 0 0 100%;
      max-width: 100%
    }

  } */