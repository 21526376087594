.float-button {
    position: fixed;
    bottom: 80px;
    right: 10px;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

/* Responsive adjustments */
/* @media (max-width: 768px) {
    .float-button {
        width: 50px;
        height: 50px;
        bottom: 80px;
        right: 8px;
    }
}

@media (max-width: 480px) {
    .float-button {
        width: 45px;
        height: 45px;
        bottom: 70px;
        right: 5px;
    }
} */
