/* Banner.css */
.test-banner-container {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 32px;
    margin: 0 200px;
    height: 220px;
  }
  
  .test-banner-text {
    color: orange;
    font-size: 1.125rem;
    font-weight: bold;
  }
  
  .test-banner-slider {
    width: 66%;
    height: auto;
    
    
  }
  
  .test-banner-slider img {
    width: 40%;
    height: 40%;
    object-fit: cover;
    margin: 0px auto
  }
  
  /* Responsive Styles */
  @media (max-width: 756px) {
    .test-banner-container {
      padding: 0 16px;
      height: 180px;
    }
    
    .test-banner-slider {
      width: 75%;
    }
    .test-banner-text{
        display: none;
    }

    .test-banner-slider {
        width: 100%;
      }
  }
  